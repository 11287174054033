import { useCallback } from 'react';
import useAxios from '@Apis/useAxios';
import { GET_SYSTEM_BUILD_TIME_API } from "@Apis/apiPath";

const useAppSystemApi = () => {
    const Axios = useAxios();

    const getBuildTimeApi = useCallback(
        async () => {
            const resp = await Axios.get(GET_SYSTEM_BUILD_TIME_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getBuildTimeApi,
    };
};

export default useAppSystemApi;
