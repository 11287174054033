import { useCallback } from 'react';
import {GET_AUTH_API, GET_LOGIN_API, GET_LOGOUT_API, GET_ME_CHECK_API, GET_ME_API} from './apiPath';
import { useHistory } from 'react-router-dom';
import useAxios from '@Apis/useAxios';
import { localUser } from '@Util/utils';
// import useShowLoading from '@Util/hook/useShowLoading';
// import { parseDate, isValidDate } from '@Util/moment';

const useAuthApi = () => {
    const Axios = useAxios();
    const history = useHistory();

    const postAuthApi = useCallback(
        async (params, fn) => {
            const resp = await Axios.post(GET_AUTH_API, params, fn);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postLoginApi = useCallback(
        async (params, fn) => {
            const resp = await Axios.post(GET_LOGIN_API, params, fn);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getLogoutApi = useCallback(
        async () => {
            await Axios.get(
                GET_LOGOUT_API,
                {},
                {
                    callbackfn: (resultData, respBody) => {
                        const { success, statusCode } = respBody.data;
                        localUser.logout();
                        if (success && statusCode === '0000') {
                            history.push('/login');
                            window.location.reload();
                        } else {
                            alert('登出失敗，請重新整理畫面');
                        }
                    },
                }
            );
        },
        // eslint-disable-next-line
        []
    );

    const getCheckLocalDataApi = useCallback(
        async () => {
            const resp = await Axios.get(GET_ME_CHECK_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getLocalMeDataApi = useCallback(
        async () => {
            const resp = await Axios.get(GET_ME_API);
            if (resp) return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        postAuthApi,
        postLoginApi,
        getLogoutApi,
        getCheckLocalDataApi,
        getLocalMeDataApi,
    };
};

export default useAuthApi;
