import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const Developer = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M19.1,6.7c0,1.1-0.8,1.9-1.9,1.9c-1,0-1.9-0.8-1.9-1.9c0-1,0.8-1.9,1.9-1.9C18.3,4.8,19.1,5.7,19.1,6.7z
	 M18,14.6v3.8c0,1.2-0.6,2.3-1.7,2.9l-4.1,2.5c-0.3,0.2-0.8,0.2-1.1,0c-0.4-0.2-0.6-0.6-0.6-1v-5.4c0-1.1-0.4-2.1-1.2-2.9
	c-0.8-0.8-1.8-1.1-2.8-1.1H1.1c-0.4,0-0.8-0.2-1-0.6c-0.2-0.4-0.2-0.8,0-1.1l2.5-4.1c0.6-1,1.7-1.7,2.9-1.7h3.8
	c3.8-6.1,9.8-6.4,13.3-5.7c0.5,0.1,1,0.5,1.1,1.1C24.4,4.8,24.2,10.8,18,14.6L18,14.6z M9.3,11.9c0.6,0.3,1.2,0.7,1.6,1.2
	c0.5,0.5,0.9,1,1.2,1.6c5-1.4,7.3-3.7,8.5-5.9c1.2-2.2,1.2-4.6,1-6.5c-1.9-0.2-4.3-0.1-6.5,1C13,4.5,10.7,6.9,9.3,11.9L9.3,11.9z
	 M2,16.2c1.6-1.6,4.2-1.6,5.8,0s1.6,4.2,0,5.8C5.5,24.3,0,24,0,24S-0.4,18.5,2,16.2L2,16.2z M3,21c0,0,1.8,0.1,2.6-0.6
	c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0C2.9,19.1,3,21,3,21z"
            />
        </SvgIcon>
    );
});

export default Developer;
