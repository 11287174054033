import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { BrowserRouter as Router, Switch, useLocation, useHistory } from 'react-router-dom';
import { PrivateRoute } from '@Components/';
import { NotFoundPage } from '@Local/Error/';
import routerMap from '@Router/router';
import { ENTRANCE_ROOT_ROUTER, MEASURE_RESULT_ROUTER } from '@Router/routerPath';
import { getIsAndroid } from '@Util/utils';
import MainLayout from '@Local/Components/Layout/MainLayout';
import { MeasurementPage } from '@Local/Measurement';

// const EmptyComponent = () => <></>;

const checkRootPath = (pathname) => {
    const regExpString = new RegExp(`^/${ENTRANCE_ROOT_ROUTER}[/]?$`);
    return regExpString.test(pathname);
};

const Main = (props) => {
    const { path: rootPath } = props;
    let { pathname } = useLocation();
    let history = useHistory();

    React.useEffect(
        () => {
            checkRootPath(pathname) && history.push(MEASURE_RESULT_ROUTER);
        },
        // eslint-disable-next-line
        [pathname],
    );

    return (
        <MainLayout>
            <Switch>
                {routerMap.map(({ path: pathRouter, exact, as: Component, ...others }) => {
                    if (!pathRouter) return null;
                    const defaultProperty = {
                        path: pathRouter,
                        exact,
                        as: Component,
                        ...others,
                    };
                    return <PrivateRoute key={uuid()} {...defaultProperty} />;
                })}
                <PrivateRoute path={`${rootPath}/404`} as={NotFoundPage} />
                <PrivateRoute path="*" as={checkRootPath(pathname) ? MeasurementPage : NotFoundPage} />
            </Switch>
        </MainLayout>
    );
};

const LandingPage = (props) => {
    useEffect(
        () => {
            if (getIsAndroid()) {
                document.body.classList.add('mobile');
            }
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <Router>
            <Main />
        </Router>
    );
};

export default LandingPage;
