import React from 'react';
import SwitchBase from '../internal/SwitchBase';
import RadioButtonIcon from './RadioButtonIcon';
import useRadioGroup from './useRadioGroup';
import { createChainedFunction } from '@Util/utils';
import clsx from 'clsx';

const staticClass = `radio`;
const colorMap = ['primary', 'secondary', 'warning', 'error', 'success', 'info'];

const areEqualValues = (a, b) => {
    if (typeof b === 'object' && b !== null) return a === b;
    // The value could be a number, the DOM will stringify it anyway.
    return String(a) === String(b);
};

const defaultCheckedIcon = <RadioButtonIcon checked />;
const defaultIcon = <RadioButtonIcon />;

const Radio = React.forwardRef((props, ref) => {
    const {
        color: propsColor = 'secondary',
        checked: checkedProp,
        name: nameProp,
        onChange: onChangeProp,
        size = 'medium',
        checkedIcon = defaultCheckedIcon,
        icon = defaultIcon,
        className,
        disabled,
        ...other
    } = props;
    let classNames = staticClass;

    const radioGroup = useRadioGroup();
    const onChange = createChainedFunction(onChangeProp, radioGroup && radioGroup.onChange);
    let checked = checkedProp;
    let color = propsColor;
    let name = nameProp;

    if (radioGroup) {
        if (typeof checked === 'undefined') {
            checked = areEqualValues(radioGroup.value, props.value);
        }
        color = radioGroup.color ? radioGroup.color : propsColor;
    }

    if (colorMap.indexOf(color) !== -1) classNames = clsx(classNames, `${staticClass}-color-${color}`);
    if (checked) classNames = clsx(classNames, `${staticClass}-checked`);
    if (disabled) classNames = clsx(classNames, `${staticClass}-disabled`);

    const defaultProperty = {
        className: clsx(classNames, className),
        type: 'radio',
        checked: checked,
        onChange: onChange,
        disabled,
        icon: React.cloneElement(icon, { fontSize: defaultIcon.props.fontSize ?? size, color }),
        checkedIcon: React.cloneElement(checkedIcon, {
            fontSize: defaultCheckedIcon.props.fontSize ?? size,
            color,
        }),
        name: name,
        ref,
        ...other,
    };

    return <SwitchBase {...defaultProperty} />;
});

export default Radio;
