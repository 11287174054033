import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const UserPlus = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M23.4,10H21V7.6C21,7.3,20.7,7,20.4,7l0,0h-1.2c-0.3,0-0.6,0.3-0.6,0.6
	c0,0,0,0,0,0V10h-2.4c-0.3,0-0.6,0.3-0.6,0.6v0v1.2c0,0.3,0.3,0.6,0.6,0.6l0,0h2.4v2.4c0,0.3,0.3,0.6,0.6,0.6l0,0h1.2
	c0.3,0,0.6-0.3,0.6-0.6v0v-2.4h2.4c0.3,0,0.6-0.3,0.6-0.6v0v-1.2C24,10.3,23.7,10,23.4,10L23.4,10z M8.4,11.8c2.7,0,4.8-2.1,4.8-4.8
	c0-2.7-2.1-4.8-4.8-4.8S3.6,4.3,3.6,7c0,0,0,0,0,0C3.6,9.7,5.7,11.8,8.4,11.8z M11.8,13h-0.6c-1.7,0.8-3.7,0.8-5.5,0H5
	c-2.8,0-5,2.3-5,5v1.6c0,1,0.8,1.8,1.8,1.8H15c1,0,1.8-0.8,1.8-1.8V18C16.8,15.3,14.5,13,11.8,13z"
            />
        </SvgIcon>
    );
});

export default UserPlus;
