import React from 'react';
import FormControlContext from './FormControlContext';
import { isCurElement, isAdornedStart, isFilled } from '@Util/utils';
import clsx from 'clsx';

const staticClass = `form-control`;
const marginMap = ['dense', 'normal'];
const directionMap = ['column', 'row'];

const FormControl = React.forwardRef((props, ref) => {
    const {
        children,
        className,
        focused: visuallyFocused,
        component: Component = 'div',
        disabled = false,
        error = false,
        fullWidth = false,
        hiddenLabel = false,
        required = false,
        color = 'primary',
        margin = 'none',
        size = 'medium',
        variant = 'outlined',
        direction = 'column',
        readOnly = false,
        ...other
    } = props;
    let classNames = `${staticClass}-root`;

    if (directionMap.indexOf(direction) !== -1)
        classNames = clsx(classNames, `${staticClass}-direction-${direction}`);
    if (marginMap.indexOf(margin) !== -1)
        classNames = clsx(classNames, `${staticClass}-margin-${margin}`);
    if (fullWidth) classNames = clsx(classNames, `${staticClass}-full-width`);
    const [adornedStart, setAdornedStart] = React.useState(() => {
        // We need to iterate through the children and find the Input in order
        // to fully support server-side rendering.
        let initialAdornedStart = false;
        if (children) {
            React.Children.forEach(children, (child) => {
                if (!isCurElement(child, ['Input', 'Select'])) return;
                const input = isCurElement(child, ['Select']) ? child.props.input : child;
                if (input && isAdornedStart(input.props)) initialAdornedStart = true;
            });
        }
        return initialAdornedStart;
    });

    const [filled, setFilled] = React.useState(() => {
        // We need to iterate through the children and find the Input in order
        // to fully support server-side rendering.
        let initialFilled = false;
        if (children) {
            React.Children.forEach(children, (child) => {
                if (!isCurElement(child, ['Input', 'Select'])) return;
                if (isFilled(child.props, true)) initialFilled = true;
            });
        }
        return initialFilled;
    });

    const [focusedState, setFocused] = React.useState(false);
    if (disabled && focusedState) {
        setFocused(false);
    }
    const focused = visuallyFocused !== undefined && !disabled ? visuallyFocused : focusedState;
    let registerEffect;
    const onFilled = React.useCallback(() => {
        setFilled(true);
    }, []);

    const onEmpty = React.useCallback(() => {
        setFilled(false);
    }, []);

    const childContext = {
        adornedStart,
        setAdornedStart,
        color,
        error,
        filled,
        focused,
        disabled,
        fullWidth,
        hiddenLabel,
        readOnly: readOnly,
        size,
        onBlur: () => {
            setFocused(false);
        },
        onEmpty,
        onFilled,
        onFocus: () => {
            setFocused(true);
        },
        required,
        variant,
        registerEffect,
    };

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
        ...other,
    };

    return (
        <FormControlContext.Provider value={childContext}>
            <Component {...defaultProperty}>{children}</Component>
        </FormControlContext.Provider>
    );
});

export default FormControl;
