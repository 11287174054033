import React from 'react';
import { Button } from '@Components/';
import useAuthApi from '@Apis/useAuthApi';

const LogoutButton = () => {
    const { getLogoutApi } = useAuthApi();

    const doLogoutApi = async () => {
        await getLogoutApi();
    };

    const handleLogoutClick = (e) => {
        doLogoutApi();
    };

    return (
        <Button variant={'outlined'} onClick={handleLogoutClick}>
            登出
        </Button>
    );
};

export default LogoutButton;
