import React from 'react';
import MeasurementBar from '../Measurement/MeasurementBar';
import clsx from 'clsx';

const Item = ({ name, value, normalRange, minValue = 0, maxValue = 99 }) => {
    return (
        <div className={'cid-box-item'}>
            <h5 className={'cid-box-item__title'}>{name}</h5>
            <MeasurementBar value={value} normalRange={normalRange} minValue={minValue} maxValue={maxValue} />
        </div>
    );
};

const CIDBox = ({ className, sourceData }) => {
    const { weightPercentage, smmPercentage, bfmPercentage } = sourceData;
    return (
        <div className={clsx('cid-box', className)}>
            <Item name={'體重'} value={weightPercentage} normalRange={[85, 115]} minValue={40} maxValue={220} />
            <Item name={'骨骼肌重'} value={smmPercentage} normalRange={[90, 110]} minValue={60} maxValue={180} />
            <Item name={'體脂肪重'} value={bfmPercentage} normalRange={[80, 160]} minValue={20} maxValue={580} />
        </div>
    );
};

export default CIDBox;
