import React from 'react';
import useForkRef from '@Util/hook/useForkRef';
import SvgIcon from '../SvgIcon';

const ShippingFast = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M23.4,13.2h-.6V9.146a1.8,1.8,0,0,0-.529-1.271L18.525,4.129A1.8,1.8,0,0,0,17.254,3.6H15.6V1.8A1.8,1.8,0,0,0,13.8,0H4.2A1.8,1.8,0,0,0,2.4,1.8V3.6H.3a.3.3,0,0,0-.3.3v.6a.3.3,0,0,0,.3.3H10.5a.3.3,0,0,1,.3.3v.6a.3.3,0,0,1-.3.3h-9a.3.3,0,0,0-.3.3v.6a.3.3,0,0,0,.3.3H9.3a.3.3,0,0,1,.3.3v.6a.3.3,0,0,1-.3.3H.3a.3.3,0,0,0-.3.3v.6a.3.3,0,0,0,.3.3H8.1a.3.3,0,0,1,.3.3v.6a.3.3,0,0,1-.3.3H2.4v4.8a3.6,3.6,0,0,0,7.2,0h4.8a3.6,3.6,0,0,0,7.2,0h1.8A.6.6,0,0,0,24,15V13.8A.6.6,0,0,0,23.4,13.2ZM6,17.4a1.8,1.8,0,1,1,1.8-1.8A1.8,1.8,0,0,1,6,17.4Zm12,0a1.8,1.8,0,1,1,1.8-1.8A1.8,1.8,0,0,1,18,17.4Zm3-7.8H15.6V5.4h1.654L21,9.146Z"
                transform="translate(0 2)"
            />
        </SvgIcon>
    );
});

export default ShippingFast;
