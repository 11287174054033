import React, { useRef, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Button, TextField, Form } from '@Components/';
import { localUser } from '@Util/utils';
import useAuthApi from '@Apis/useAuthApi';
import { MEMBER_ROOT_ROUTER } from '@Router/routerPath';

import LoginLogo from '@Images/logo-white.svg';
import { generateTokenAuth } from '@Util/apiCryto';
import { parseDate } from '@Util/moment';

// 登入頁面
const LoginPage = () => {
    const history = useHistory();
    const [clubName, setClubName] = useState('');
    const { enqueueSnackbar: _snackbar } = useSnackbar();
    const { postAuthApi, postLoginApi, getLogoutApi, getCheckLocalDataApi, getLocalMeDataApi } = useAuthApi();
    const memberNoRef = useRef(null);
    const captchaRef = useRef(null);

    const handleLoginApi = (e) => {
        e.preventDefault();
        e.stopPropagation();
        getResult();
    };

    const getResult = () => {
        if (!memberNoRef.current.isError() && !captchaRef.current.isError()) {
            const usr = memberNoRef.current.getResult();
            const pwd = captchaRef.current.getResult();
            doLoginApi(usr, pwd);
        }
    };
    
    const doAuthApi = async (tokenKey) => {
        await postAuthApi(
            {tokenKey},
            {
                callbackfn: (oriData, apiData) => {
                    const { data } = apiData;
                    if (data.success) {
                        authAction(data.result);
                    }
                }
            }
        )
    }
    
    const authAction = async (result) => {
        if (result !== '') {
            setClubName(result);
        }else {
            localUser.setToken(null);
        }
    }

    const doLoginApi = async (memberNo, captcha) => {
        const { hmacSecurity, hmacKey } = generateTokenAuth(captcha);
        const tokenKey = localUser.getToken();
        await postLoginApi(
            { tokenKey, memberNo, captcha: hmacSecurity, hmacKey },
            {
                callbackfn: (oriData, apiData) => {
                    const { data } = apiData;
                    if (data.success) {
                        loginAction(data.result);
                    }
                },
            },
        );
    };

    const loginAction = async (result) => {
        const { memberNo = '', displayName = '' } = result;
        if (memberNo !== '' && displayName !== '') {
            localUser.set(result);
            history.push(MEMBER_ROOT_ROUTER);
        } else {
            _snackbar('驗證錯誤，請通知教練協助排解', { variant: 'error' });
            await getLogoutApi();
        }
    };

    useEffect(
        () => {
            document.body.className = 'login-page';
            const fetchData = async () => {
                let resp = await getCheckLocalDataApi();
                let tokenKey = localUser.getToken();
                
                if (resp) {
                    resp = await getLocalMeDataApi();
                    resp && loginAction(resp);
                }
                else if (tokenKey) {
                    doAuthApi(tokenKey);
                }
            };
            fetchData();
            return () => {
                document.body.className = '';
            };
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            <div className="login-body">
                <div className="container">
                    <div className="logo-wrap">
                        <img src={LoginLogo} className="login-logo" alt="curves" />
                    </div>
                    <Form className="login-form" onSubmit={handleLoginApi}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField ref={memberNoRef} label={`${clubName} 會員編號 `} fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField ref={captchaRef} label="驗證碼" fullWidth />
                            </Grid>
                        </Grid>
                        <div className="login-btn-confirm">
                            <Button variant="outlined" type="submit">
                                送出驗證
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
            <div className="login-footer">
                <div className="container">
                    <div className="login-copywrite ban-select">
                        {`版權所有 © ${parseDate(new Date(), 'YYYY')} Curves`}
                        <br />
                        可爾姿女性30分鐘環狀運動
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default LoginPage;
