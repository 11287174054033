import React, { useEffect, useState } from 'react';
import { ClickAwayListener, IconButton, Tooltip } from '@Components/';
import clsx from 'clsx';

const FloatButton = ({ children, ...props }) => {
    const { className, tip, onClick } = props;
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipToggle = () => {
        setOpen((prev) => !prev);
    };

    const handleOnClick = (e) => {
        handleTooltipToggle();
        if (typeof onClick === 'function') {
            onClick(e);
        }
    };

    useEffect(
        () => {
            if (open) {
                setTimeout(() => {
                    setOpen(false);
                }, 3000);
            }
        },
        // eslint-disable-next-line
        [open],
    );

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                title={tip}
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
            >
                <IconButton {...props} className={clsx('float-button', className)} onClick={handleOnClick}>
                    {children}
                </IconButton>
            </Tooltip>
        </ClickAwayListener>
    );
};

export default FloatButton;
