import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const NoPushForMonths = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#51A69E', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12s12-5.4,12-12C24,5.4,18.6,0,12,0z M12,19.5c-3,0.2-4.8-1.1-5.5-3.8
	l3.3-0.7c0.1,1.2,1.1,2.2,2.3,2.1c0,0,0.1,0,0.1,0c1.1,0.1,2-0.7,2.1-1.7c0-0.1,0-0.2,0-0.3c0-1.4-1-2-3.2-2H9.8v-2.4H11
	c1.9,0,2.9-0.6,2.9-1.9c0.1-0.9-0.6-1.7-1.5-1.8c-0.1,0-0.3,0-0.4,0c-1.2,0-2.2,0.9-2.3,2.1L6.7,8.4c0.6-2.6,2.5-3.9,5.7-4
	c3,0.1,4.7,1.4,4.9,3.8c0,1.8-1,3-3.1,3.4v0.1c2.1,0.4,3.3,1.5,3.4,3.6C17.4,18,15.5,19.4,12,19.5z"
            />
        </SvgIcon>
    );
});

export default NoPushForMonths;
