import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { SelectWithOptions } from '@Local/Components';
import { IconButton } from '@Components/';
import { KeyboardArrowLeft as KeyboardArrowLeftIcon, KeyboardArrowRight as KeyboardArrowRightIcon } from '@SvgIcon/';
import clsx from 'clsx';
import useMemberInbodyApi from '@Apis/useMemberInbodyApi';
import { isEmpty } from '@Util/utils';

const MeasurementDateController = ({ className, setMeasureID }) => {
    const { getMemberInbodyOptionsApi } = useMemberInbodyApi();
    const [options, setOptions] = useState({});
    const { measureOption = [] } = options;
    const [selectedIndex, setSelectedIndex] = useState(0);
    const keyRef = useRef(uuid());

    const getOptionsApi = async () => {
        const resp = await getMemberInbodyOptionsApi();
        if (resp) {
            setOptions(resp);
        }
    };

    const handlePrevClick = () => {
        if (selectedIndex === 0) alert('已經是第一筆');
        else {
            setSelectedIndex((prev) => prev - 1);
        }
    };

    const handleNextClick = () => {
        if (selectedIndex === measureOption.length - 1) alert('已經是最後一筆');
        else {
            setSelectedIndex((prev) => prev + 1);
        }
    };

    const handleDateChange = (event, node, _value) => {
        measureOption.findIndex((item, index) => {
            if (item.value === _value) {
                setSelectedIndex(index);

                return true;
            }
            return false;
        });
    };

    useEffect(
        () => {
            getOptionsApi();
        },
        // eslint-disable-next-line
        [],
    );

    useEffect(
        () => {
            if (!isEmpty(measureOption) && typeof setMeasureID === 'function') {
                setMeasureID(measureOption[selectedIndex]?.value);
            }
        },
        // eslint-disable-next-line
        [measureOption, selectedIndex],
    );

    return (
        <div className={clsx('measurement-date-controller', className)}>
            <IconButton
                className={'measurement-date-controller__button measurement-date-controller__button--prev'}
                onClick={handlePrevClick}
                disabled={selectedIndex === 0}
            >
                <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
                className={'measurement-date-controller__button measurement-date-controller__button--next'}
                onClick={handleNextClick}
                disabled={selectedIndex === measureOption.length - 1}
            >
                <KeyboardArrowRightIcon />
            </IconButton>
            <SelectWithOptions
                key={keyRef.current}
                defaultValue={measureOption[selectedIndex]?.value}
                className={'measurement-date-controller__select'}
                options={measureOption}
                onChange={handleDateChange}
            />
        </div>
    );
};

export default MeasurementDateController;
