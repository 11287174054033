import React from 'react';
import clsx from 'clsx';
import ListContext from './ListContext';
import { Typography } from '@Components/';

const staticClass = 'list-item-text';

const ListItemText = React.forwardRef((props, ref) => {
    const { className, primary: primaryProp, secondary: secondaryProp, inset = false, disableTypography = false, children, ...other } = props;
    const { dense } = React.useContext(ListContext);
    let classNames = staticClass;
    let primary = primaryProp != null ? primaryProp : children;
    let secondary = secondaryProp;

    if (primary != null && primary.type !== Typography && !disableTypography) {
        primary = (
            <Typography variant={dense ? 'body2' : 'body1'} className={`${staticClass}-primary`} component="span" display="block">
                {primary}
            </Typography>
        );
    }

    if (secondary != null && secondary.type !== Typography && !disableTypography) {
        secondary = (
            <Typography className={`${staticClass}-secondary`} variant="body2" display="block">
                {secondary}
            </Typography>
        );
    }
    if (primary && secondary && !disableTypography) classNames = clsx(classNames, `${staticClass}-group`);
    if (inset) classNames = clsx(classNames, `${staticClass}-inset`);

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
        ...other,
    };

    return (
        <div {...defaultProperty}>
            {primary}
            {secondary}
        </div>
    );
});

export default ListItemText;
