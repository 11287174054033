/*
    新增頁面 ADD
    /XXX/XXX/add
    編輯頁面 EDIT
    /XXX/XXX/edit/(:id)
    唯獨模式 READ ONLY
    /XXX/XXX/read-only/(:id)
*/

/* 登陸與ROOT */
export const ENTRANCE_ROOT_ROUTER = `member`;
export const LOGIN_ROOT_ROUTER = `/login/`;
export const MEMBER_ROOT_ROUTER = `/member`;

// export const ECOMMERCE_ROOT_ROUTER = `/ec`;

export const MEASURE_ROUTER = `/member/measure/`;
export const MEASURE_RESULT_ROUTER = `/member/measure/result/`;
export const MEASURE_TRANSCRIPT_ROUTER = `/member/measure/transcript/`;
