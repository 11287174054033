import React from 'react';
import clsx from 'clsx';
import { Paper } from '@Components/';

const staticClass = `snackbar-content`;
const colorMap = ['primary', 'secondary', 'success', 'error', 'warning', 'info'];

const SnackbarContentRoot = React.forwardRef((props, ref) => {
    const { className, children, color = 'primary' } = props;
    let classNames = `${staticClass}-root`;

    classNames = clsx(classNames, `${staticClass}-color-${colorMap.includes(color) ? color : `primary`}`);

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
    };
    return <Paper {...defaultProperty}>{children}</Paper>;
});

const SnackbarContentMessage = React.forwardRef((props, ref) => {
    const { children, className } = props;
    let classNames = `${staticClass}-message`;
    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
    };
    return <div {...defaultProperty}>{children}</div>;
});

const SnackbarContentAction = React.forwardRef((props, ref) => {
    const { children, className } = props;
    let classNames = `${staticClass}-action`;
    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
    };
    return <div {...defaultProperty}>{children}</div>;
});

const SnackbarContent = React.forwardRef((props, ref) => {
    const { action, className, message, role = 'alert', ...other } = props;
    const defaultProperty = {
        role: role,
        square: true,
        elevation: 6,
        className,
        ref,
        ...other,
    };

    return (
        <SnackbarContentRoot {...defaultProperty}>
            <SnackbarContentMessage>{message}</SnackbarContentMessage>
            {action ? <SnackbarContentAction>{action}</SnackbarContentAction> : null}
        </SnackbarContentRoot>
    );
});

export default SnackbarContent;
