import React from 'react';
import { Typography } from '@Components/';
import useFormControl from '../formControl/useFormControl';
import clsx from 'clsx';

const staticClass = `form-control-label`;
const labelPlacementMap = ['start', 'top', 'bottom', 'end']; //end is default

const FormControlLabel = React.forwardRef((props, ref) => {
    const { className, control, disabled: disabledProp, disableTypography, prefixLabel = '', label = '', labelPlacement = 'end', onChange, ...other } = props;
    const formControl = useFormControl();
    let classNames = `${staticClass}-root`;
    let disabled = disabledProp;
    if (typeof disabled === 'undefined' && typeof control.props.disabled !== 'undefined') {
        disabled = control.props.disabled;
    }
    if (typeof disabled === 'undefined' && formControl) {
        disabled = formControl.disabled;
    }

    let controlProps = {
        disabled,
    };

    if (label !== '') classNames = clsx(classNames, `${staticClass}-label`);
    if (disabled) classNames = clsx(classNames, `${staticClass}-disabled`);
    if (labelPlacementMap.indexOf(labelPlacement) !== -1) classNames = clsx(classNames, `${staticClass}-label-placement-${labelPlacement}`);

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
        ...other,
    };

    ['checked', 'name', 'onChange', 'value', 'inputRef'].forEach((key) => {
        if (typeof control.props[key] === 'undefined' && typeof props[key] !== 'undefined') {
            controlProps[key] = props[key];
        }
    });

    return (
        <label {...defaultProperty}>
            {prefixLabel.type === Typography || disableTypography ? (
                prefixLabel
            ) : (
                <Typography component="span" mb className={`${staticClass}-text`}>
                    {prefixLabel}
                </Typography>
            )}
            {React.cloneElement(control, { ...other, ...controlProps })}
            {label.type === Typography || disableTypography ? (
                label
            ) : (
                <Typography component="span" mb className={`${staticClass}-text`}>
                    {label}
                </Typography>
            )}
        </label>
    );
});

export default FormControlLabel;
