import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const Heartbeat = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M15.009,11.428l-2.33,4.659a.749.749,0,0,1-1.355-.028l-2.667-5.92L7.252,13.5H2.841L11.4,22.242a.835.835,0,0,0,1.2,0L21.159,13.5H16.045l-1.036-2.072Zm7.2-7.964-.112-.117a6.141,6.141,0,0,0-8.784,0L12,4.688,10.692,3.352a6.133,6.133,0,0,0-8.784,0L1.8,3.464A6.436,6.436,0,0,0,1.453,12h4.8L7.936,7.959a.751.751,0,0,1,1.378-.019L12.042,14l2.3-4.589a.749.749,0,0,1,1.341,0L16.973,12h5.573A6.436,6.436,0,0,0,22.2,3.464Z"
                transform="translate(0.001 -0.497)"
            />
        </SvgIcon>
    );
});

export default Heartbeat;
