import React, { useEffect, useState } from 'react';
import body_type_img from '@Images/body-type.png';
import clsx from 'clsx';

const calculatePercentage = (value, normalRange, minValue, maxValue) => {
    const numericValue = parseFloat(value);
    if (numericValue < normalRange[0]) {
        return (numericValue / normalRange[0]) * 25;
    } else if (numericValue >= normalRange[0] && numericValue <= normalRange[1]) {
        return 25 + ((numericValue - normalRange[0]) / (normalRange[1] - normalRange[0])) * 50;
    } else {
        return 75 + ((numericValue - normalRange[1]) / (maxValue - normalRange[1])) * 25;
    }
};

const BodyTypeMapBox = ({ className, bmi, pbf }) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    useEffect(
        () => {
            const calculatePosition = (bmi = 0, pbf = 0) => {
                const x = calculatePercentage(pbf, [18, 28], 0, 99);
                const y = calculatePercentage(bmi, [18.5, 24], 0, 99);
                return { x, y };
            };

            setPosition(calculatePosition(bmi, pbf));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [bmi, pbf],
    );
    return (
        <div className={clsx('body-type-box', className)}>
            <div className={'body-type-box__wrap'}>
                <img className={'body-type-box__img'} src={body_type_img} alt={"inbody"}/>
                <div className={'body-type-box__map'}>
                    <div className="body-type-box__position" style={{ left: `${position.x}%`, bottom: `${position.y}%` }}></div>
                </div>
            </div>
        </div>
    );
};

export default BodyTypeMapBox;
