import React, { useImperativeHandle } from 'react';
import clsx from 'clsx';
import { Autocomplete } from '@mui/material';
import { OutlinedInput, FormLabel, Stack, FormHelperText, FormControl, Typography, Box } from '@Components/';
import { isEmpty, findIndexFn } from '@Util/utils';

// multiple 待處理 todo by Allen

const initValue = (props) => {
    const { value: _value, key, options } = props;
    let _index = -1;
    if (isEmpty(_value)) return undefined;
    _index = findIndexFn(options, key, _value);
    if (_index === -1) return undefined;
    return options[_index];
};

const initInputValue = (props) => {
    const { value: option, getOptionLabel } = props;
    if (isEmpty(option)) return '';
    return getOptionLabel(option);
};

// is uncontrolled Component
const AutocompleteField = React.forwardRef((props, ref) => {
    const {
        // Autocomplete use
        options = [],
        optionKeys = ['name', 'mobile'],
        primaryKey = 'id',
        defaultValue: defaultValueProps = undefined,
        onChange: onChangeProps,
        label,
        name: nameProps,
        disabled = false,
        fullWidth = false,
        required = false,
        className,
        isError: propsError = false,
        isLightColor = false,
        direction: directionProps = 'column',
        helperText = '此欄位為必填',
        InputProps = {},
        inputProps: inputPropsProps = {},
    } = props;

    const [isError, setError] = React.useState(propsError);

    React.useLayoutEffect(() => {
        if (isEmpty(primaryKey)) console.error('Please give primaryKey');
        // eslint-disable-next-line
    }, []);

    const getOptionLabel = React.useCallback(
        (_option) => {
            if (!Array.isArray(optionKeys)) {
                console.error('optionKeys must be Array');
                return 'error';
            }
            return optionKeys
                .reduce((cur, _key) => {
                    const _value = _option[_key];
                    let newAry = [...cur];
                    if (_value) newAry.push(_value);
                    return newAry;
                }, [])
                .join(', ');
        },
        [optionKeys]
    );

    const [defaultValue] = React.useState(initValue({ value: defaultValueProps, options, key: primaryKey }));
    const [value, setValue] = React.useState(defaultValue);
    const [inputValue, setInputValue] = React.useState(initInputValue({ value: defaultValue, getOptionLabel }));
    const inputRef = React.useRef(null);

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: (_key = '') => {
                    if (!value) return '';
                    return _key ? value[_key] : value;
                },
                isError: () => {
                    let error = required;
                    if (!isEmpty(value)) error = false;
                    setError(error);
                    return error;
                },
                getName: () => nameProps,
            };
        },
        // eslint-disable-next-line
        [value]
    );

    const onInputChange = (event, newInputValue) => setInputValue(newInputValue);

    const onChange = (event, newValue) => {
        setValue(newValue);
        onChangeProps && onChangeProps(newValue);
    };

    const isOptionEqualToValue = (_option, _value) => {
        const targetKey = _option?.[primaryKey] ?? null;
        const targetValue = _value?.[primaryKey] ?? null;
        return String(targetKey) === String(targetValue);
    };

    const renderOption = (props, _option) => {
        const { key: label } = props;
        return (
            <Box {...props} key={`${_option[primaryKey]}_${label}`}>
                <Typography component="span">{label}</Typography>
            </Box>
        );
    };

    const renderInput = ({ inputProps, ...params }) => {
        const defaultProperty = {
            disabled: params.disabled,
            fullWidth: params.fullWidth,
            InputProps: { ...params.InputProps, ...InputProps },
            inputProps: { ...inputProps, ...inputPropsProps },
            ...params,
            name: nameProps,
        };

        return <OutlinedInput {...defaultProperty} inputRef={inputRef} />;
    };

    const autoCompleteProperty = {
        defaultValue,
        options: options,
        onChange: onChange,
        inputValue: inputValue,
        onInputChange: onInputChange,
        getOptionLabel: getOptionLabel,
        isOptionEqualToValue: isOptionEqualToValue,
        renderInput: renderInput,
        renderOption: renderOption,
        noOptionsText: <Typography component="span">尚無資料</Typography>,
        ...InputProps,
    };

    return (
        <FormControl
            disabled={disabled}
            fullWidth={fullWidth}
            required={required}
            className={clsx('autocomplete-field', className)}
            direction={directionProps}
            error={isError}
        >
            {label && <FormLabel lightColor={isLightColor}>{label}</FormLabel>}
            <Stack direction="column" className="field-input-root">
                <Autocomplete {...autoCompleteProperty} />
                {isError && <FormHelperText>{helperText}</FormHelperText>}
            </Stack>
        </FormControl>
    );
});

export default AutocompleteField;
