import React, { useRef, useState } from 'react';
import { NavLink, Switch, Redirect } from 'react-router-dom';
import { Tab, Tabs, Box, PrivateRoute } from '@Components/';
import { MeasurementResultsPage, MeasurementTranscriptPage } from '@Local/Measurement/';
import { MEASURE_RESULT_ROUTER, MEASURE_ROUTER, MEASURE_TRANSCRIPT_ROUTER } from '@Router/routerPath';
import MeasurementDateController from './Components/Measurement/MeasurementDateController';
import { MeasurementProvider } from '@Local/Measurement/Components/MeasurementContext/MeasurementContext';

const MeasurementPage = (props) => {
    const [measureID, setMeasureID] = useState(0);
    const pathname = props.location.pathname;
    const dialogRef = useRef(null);

    return (
        <MeasurementProvider value={{ measureID, setMeasureID, dialogRef }}>
            <Box className={'measurement-menu'}>
                <Tabs value={pathname} className={'measurement-menu--container'}>
                    <Tab label={'量身結果'} value={MEASURE_RESULT_ROUTER} component={NavLink} to={MEASURE_RESULT_ROUTER} />
                    <Tab label={'成績單'} value={MEASURE_TRANSCRIPT_ROUTER} component={NavLink} to={MEASURE_TRANSCRIPT_ROUTER} />
                </Tabs>
            </Box>
            <Box className={'container p-2'}>
                <MeasurementDateController setMeasureID={setMeasureID} />
            </Box>
            <Switch>
                <PrivateRoute path={MEASURE_RESULT_ROUTER} as={MeasurementResultsPage} exact />
                <PrivateRoute path={MEASURE_TRANSCRIPT_ROUTER} as={MeasurementTranscriptPage} exact />
                <Redirect from={MEASURE_ROUTER} to={MEASURE_RESULT_ROUTER} exact />
                {/*<Redirect from={MEMBER_ROOT_ROUTER} to={MEASURE_RESULT_ROUTER} exact />*/}
            </Switch>
        </MeasurementProvider>
    );
};

export default MeasurementPage;
