import React from 'react';
import { parseDate } from '@Util/moment';

const MainHeader = () => {
    return (
        <footer className={'footer'}>
            <div className="container">
                <div className={'ban-select'}>{`Copyright © ${parseDate(new Date(), 'YYYY')} Curves All rights reserved.`}</div>
            </div>
        </footer>
    );
};

export default MainHeader;
