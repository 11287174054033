import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const UserMinus = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M23.4,10.1h-7.2c-0.3,0-0.6,0.3-0.6,0.6V12c0,0.3,0.3,0.6,0.6,0.6h7.2
	c0.3,0,0.6-0.3,0.6-0.6v-1.2C24,10.4,23.7,10.1,23.4,10.1z M8.4,12c2.7-0.1,4.9-2.3,4.8-5c0,0,0,0,0,0c0.1-2.7-2.1-4.9-4.8-5
	c0,0,0,0,0,0C5.7,2.1,3.5,4.3,3.6,7c0,0,0,0,0,0C3.5,9.7,5.7,11.9,8.4,12C8.4,12,8.4,12,8.4,12z M11.8,13.2h-0.6
	c-1.7,0.8-3.7,0.8-5.5,0H5c-2.8,0.1-5.1,2.4-5,5.2v1.6c0,1,0.8,1.9,1.8,1.9H15c1,0,1.8-0.9,1.8-1.9v-1.6
	C16.9,15.7,14.6,13.3,11.8,13.2z"
            />
        </SvgIcon>
    );
});

export default UserMinus;
