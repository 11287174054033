import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const Print = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M6,0C4.3,0,3,1.3,3,3v4.5h3V3h10.6L18,4.4v3.1h3V4.4c0-0.8-0.3-1.6-0.9-2.1l-1.4-1.4C18.2,0.3,17.4,0,16.6,0H6z M18,16.5V18
	v3H6v-3v-0.8v-0.8H18z M21,18h1.5c0.8,0,1.5-0.7,1.5-1.5V12c0-1.7-1.3-3-3-3H3c-1.7,0-3,1.3-3,3v4.5C0,17.3,0.7,18,1.5,18H3v3
	c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3V18z M20.2,11.6c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1S19.6,11.6,20.2,11.6z"
            />
        </SvgIcon>
    );
});

export default Print;
