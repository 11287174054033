import React from 'react';
import clsx from 'clsx';
import ListContext from '../lists/ListContext';
import ButtonBase from '../button/ButtonBase';
import useEnhancedEffect from '@Util/hook/useEnhancedEffect';
import useForkRef from '@Util/hook/useForkRef';

const staticClass = `menu-item`;

const MenuItem = React.forwardRef((props, ref) => {
    const {
        autoFocus = false,
        component = 'li',
        dense = false,
        divider = false,
        disableGutters = false,
        disabled,
        focusVisibleClassName,
        role = 'menuitem',
        tabIndex: tabIndexProp,
        selected,
        ...other
    } = props;
    let classNames = staticClass;
    const context = React.useContext(ListContext);
    const childContext = {
        dense: dense || context.dense || false,
        disableGutters,
    };
    const menuItemRef = React.useRef(null);

    if (divider) classNames = clsx(classNames, `${staticClass}-divider`);
    if (disableGutters) classNames = clsx(classNames, `${staticClass}-disable-gutters`);
    if (dense) classNames = clsx(classNames, `${staticClass}-dense`);
    if (disabled) classNames = clsx(classNames, `${staticClass}-disabled`);
    if (selected) classNames = clsx(classNames, `${staticClass}-selected`);

    useEnhancedEffect(() => {
        if (autoFocus) {
            if (menuItemRef.current) menuItemRef.current.focus();
        }
    }, [autoFocus]);

    const handleRef = useForkRef(menuItemRef, ref);

    let tabIndex;
    if (!props.disabled) {
        tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
    }

    const defaultProperty = {
        className: classNames,
        selected,
        disabled,
        ...other,
    };

    return (
        <ListContext.Provider value={childContext}>
            <ButtonBase ref={handleRef} role={role} tabIndex={tabIndex} component={component} {...defaultProperty} />
        </ListContext.Provider>
    );
});

export default MenuItem;
