import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const DisplayCode = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="M23.99,.55C24,.13,23.91,0,23.46,0,15.82,.02,8.18,.02,.54,0,.11,0,0,.11,0,.54,.02,5.94,.02,11.34,0,16.74c0,.5,.14,.59,.61,.59,3.11-.02,6.21-.01,9.39-.01-.1,.31-.21,.55-.26,.8-.08,.45-.33,.56-.77,.54-1.07-.04-2.15,0-3.22-.02-.29,0-.43,.05-.41,.38,.03,.65,.02,1.29,0,1.94,0,.26,.06,.35,.33,.35,4.22,0,8.43,0,12.65,0,.27,0,.35-.07,.34-.34-.02-.65-.02-1.3,0-1.94,.01-.33-.1-.4-.41-.39-.87,.02-1.74,0-2.61,0q-1.23,0-1.59-1.16s0-.1-.01-.18c3.12,0,6.23,0,9.33,.01,.46,0,.62-.06,.62-.58-.02-5.4-.02-10.8,0-16.2Zm-2.66,13.61c0,.42-.1,.51-.51,.51-5.88-.02-11.76-.01-17.64,0-.41,0-.52-.09-.51-.51,.02-3.66,.02-7.32,0-10.98,0-.42,.1-.52,.51-.51,2.94,.02,5.88,.01,8.82,.01s5.88,0,8.82-.01c.41,0,.52,.09,.51,.51-.02,3.66-.02,7.32,0,10.98Zm-10.51-3.75c.07,.08,.14,.16,.23,.26-.13,.14-.23,.27-.35,.38-.35,.32-.63,.92-1.04,.91-.35-.02-.68-.56-1-.88-.71-.7-1.4-1.42-2.12-2.11-.22-.21-.24-.34-.01-.57,.97-.94,1.92-1.89,2.86-2.86,.22-.23,.36-.23,.57,0,.34,.39,.95,.73,.99,1.13,.04,.42-.62,.75-.98,1.12-.88,.9-.88,.89,0,1.77,.29,.29,.58,.57,.86,.86Zm6.64-2.03c.21,.21,.25,.34,.02,.57-.97,.94-1.92,1.89-2.86,2.86-.22,.23-.36,.24-.57,0-.34-.39-.95-.72-.99-1.13-.04-.42,.63-.74,.97-1.12,.21-.23,.44-.44,.66-.67,.06-.06,.12-.13,.21-.23-.1-.1-.18-.2-.27-.29-.48-.48-.96-.98-1.45-1.44-.21-.2-.22-.33,0-.53,.32-.28,.62-.58,.9-.9,.2-.23,.33-.19,.53,.01,.94,.96,1.9,1.92,2.86,2.86Z" />
        </SvgIcon>
    );
});

export default DisplayCode;
