import React from 'react';
import clsx from 'clsx';
import ButtonBase from '../button/ButtonBase';

const staticClass = 'tab';
const textColorArray = ['primary', 'secondary'];
const iconPositionArray = ['start', 'end'];

const Tab = React.forwardRef((props, ref) => {
    const {
        className,
        disabled = false,
        disableFocusRipple = false,
        // eslint-disable-next-line react/prop-types
        fullWidth,
        icon,
        // eslint-disable-next-line react/prop-types
        indicator,
        label,
        onChange,
        onClick,
        onFocus,
        // eslint-disable-next-line react/prop-types
        selected,
        // eslint-disable-next-line react/prop-types
        selectionFollowsFocus,
        // eslint-disable-next-line react/prop-types
        textColor = 'primary',
        value,
        wrapped = false,
        ariaControls,
        iconPosition = 'start',
        ...other
    } = props;
    let classNames = `${staticClass}-root`;

    if (icon && label && iconPositionArray.indexOf(iconPosition) !== -1)
        classNames = clsx(classNames, `${staticClass}-icon`, `${staticClass}-label`, `${staticClass}-icon-${iconPosition}`);
    if (textColorArray.indexOf(textColor) !== -1) classNames = clsx(classNames, `${staticClass}-${textColor}`);
    if (selected) classNames = clsx(classNames, `${staticClass}-selected`);
    if (disabled) classNames = clsx(classNames, `${staticClass}-disabled`);
    if (fullWidth) classNames = clsx(classNames, `${staticClass}-full-width`);
    if (wrapped) classNames = clsx(classNames, `${staticClass}-wrapped`);

    const handleClick = (event) => {
        if (!selected && onChange) onChange(event, value);
        onClick && onClick(event);
    };

    const handleFocus = (event) => {
        if (selectionFollowsFocus && !selected && onChange) onChange(event, value);
        onFocus && onFocus(event);
    };

    return (
        <ButtonBase
            focusRipple={!disableFocusRipple}
            className={clsx(classNames, className)}
            ref={ref}
            role="tab"
            aria-selected={selected}
            disabled={disabled}
            onClick={handleClick}
            onFocus={handleFocus}
            tabIndex={selected ? 0 : -1}
            {...other}
        >
            {icon &&
                React.cloneElement(icon, {
                    color: selected ? textColor : undefined,
                    disabled,
                })}
            {label}
            {indicator}
        </ButtonBase>
    );
});

export default Tab;
