import { MEASURE_RESULT_ROUTER, MEASURE_ROUTER, MEASURE_TRANSCRIPT_ROUTER } from '@Router/routerPath';
import { MeasurementPage } from '@Local/Measurement';

const MeasurementRouter = [
    {
        description: '量身',
        static: true,
        path: MEASURE_ROUTER,
        href: MEASURE_ROUTER,
        as: MeasurementPage,
    },
    {
        description: '量身結果',
        static: true,
        path: MEASURE_RESULT_ROUTER,
        href: MEASURE_RESULT_ROUTER,
        as: MeasurementPage,
    },
    {
        description: '成績單',
        static: true,
        path: MEASURE_TRANSCRIPT_ROUTER,
        href: MEASURE_TRANSCRIPT_ROUTER,
        as: MeasurementPage,
    },
];

export default MeasurementRouter;
