import React from 'react';
import PaginationItem from '../paginationItem/PaginationItem';
import usePagination from '@Util/hook/usePagination';

const PaginationRoot = React.forwardRef((props, ref) => {
    const { children } = props;
    const defaultProperty = {
        ref,
        'aria-label': 'pagination navigation',
    };

    return <nav {...defaultProperty}>{children}</nav>;
});

const PaginationUl = (props) => {
    const { children } = props;
    const staticClass = 'pagination';
    let classNames = `${staticClass}-ul`;
    return <ul className={classNames}>{children}</ul>;
};

const defaultGetAriaLabel = (type, page, selected) => {
    if (type === 'page') {
        return `${selected ? '' : 'Go to '}page ${page}`;
    }
    return `Go to ${type} page`;
};

const Pagination = React.forwardRef((props, ref) => {
    const {
        disabled = false,
        boundaryCount = 1,
        color = 'standard',
        count = 1,
        defaultPage = 1,
        getItemAriaLabel = defaultGetAriaLabel,
        hideNextButton = false,
        hidePrevButton = false,
        // onChange,
        // page,
        renderItem = (item) => <PaginationItem {...item} />,
        shape = 'circular',
        showFirstButton = false,
        showLastButton = false,
        siblingCount = 1,
        size = 'medium',
        variant = 'text',
    } = props;

    const ownerState = {
        ...props,
        boundaryCount,
        color,
        count,
        defaultPage,
        disabled,
        getItemAriaLabel,
        hideNextButton,
        hidePrevButton,
        renderItem,
        shape,
        showFirstButton,
        showLastButton,
        siblingCount,
        size,
        variant,
      };

    const { items } = usePagination({ ...ownerState, componentName: 'Pagination' });

    return (
        <PaginationRoot ref={ref}>
            <PaginationUl>
                {items.map((item, index) => (
                    <li key={index}>
                        {renderItem({
                            ...item,
                            color,
                            'aria-label': getItemAriaLabel(item.type, item.page, item.selected),
                            shape,
                            size,
                            variant,
                        })}
                    </li>
                ))}
            </PaginationUl>
        </PaginationRoot>
    );
});

export default Pagination;
