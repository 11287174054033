import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const MoreVertical = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M3.75,7.188A2.813,2.813,0,1,1,.938,10,2.811,2.811,0,0,1,3.75,7.188ZM.938,3.125A2.813,2.813,0,1,0,3.75.313,2.811,2.811,0,0,0,.938,3.125Zm0,13.75A2.813,2.813,0,1,0,3.75,14.063,2.811,2.811,0,0,0,.938,16.875Z"
                transform="translate(9.063 1.688)"
            />
        </SvgIcon>
    );
});

export default MoreVertical;
