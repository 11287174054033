import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const BirthdayCake = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#6E488B', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M12,0C-3.9,0.3-3.9,23.7,12,24C27.9,23.7,27.9,0.3,12,0z M15.3,3.9c0.4,0.7,1.3,1.8,0.4,2.4l0,1.7h-0.8V6.2
	C14,5.7,14.9,4.5,15.3,3.9z M12,3.9c0.4,0.6,1.3,1.8,0.4,2.3l0,1.7h-0.8V6.3C10.6,5.7,11.6,4.5,12,3.9z M8.7,3.9
	C9.1,4.5,10,5.7,9.1,6.2l0,1.7H8.3V6.2C7.4,5.7,8.3,4.5,8.7,3.9z M7,8.8H17V12H7V8.8z M18.6,18.6H5.4v-2.8c2.1,2.3,3.4-1.2,5.2,0.2
	c1.6,1.9,3.3-1.4,4.9,0c0.4,0.4,0.9,0.6,1.5,0.6v0c0.7,0,1.2-0.4,1.7-0.8V18.6z M18.6,14.9c-2.4,2.4-3.4-1.5-5.7,0.5
	c-1.6,1.3-3.2-1.9-4.8,0c-0.9,1-1.9,0-2.7-0.5v-2.1h13.2V14.9z"
            />
        </SvgIcon>
    );
});

export default BirthdayCake;
