import React from 'react';
import clsx from 'clsx';
import { isFilled, ownerDocument, isValid } from '@Util/utils';
import useControlled from '@Util/hook/useControlled';
import useForkRef from '@Util/hook/useForkRef';
import { Menu } from '@Components/';
import { ArrowDropDown as ArrowDropDownIcon } from '@SvgIcon/';

const SelectRoot = React.forwardRef((props, ref) => {
    const staticClass = `select-root`;
    const { className, children, ...other } = props;
    let classNames = `${staticClass}`;
    classNames = clsx('input-base-component', 'outlined-input-input', classNames, 'select');
    return (
        <div className={clsx(classNames, className)} ref={ref} {...other}>
            {children}
        </div>
    );
});

const SelectIcon = (props) => {
    const { as: Component, open, disabled, className } = props;
    const staticClass = `select-icon`;
    let classNames = staticClass;
    if (open) classNames = clsx(classNames, `${classNames}-open`);
    if (disabled) classNames = clsx(classNames, `${classNames}-disabled`);

    return <Component className={clsx(classNames, className)} />;
};

const SelectNativeInput = React.forwardRef((props, ref) => {
    const { className, ...other } = props;
    const staticClass = `select-input`;
    let classNames = staticClass;
    return <input className={clsx(classNames, className)} ref={ref} {...other} />;
});

const areEqualValues = (a, b) => {
    if (typeof b === 'object' && b !== null) return a === b;
    // The value could be a number, the DOM will stringify it anyway.
    return String(a) === String(b);
};

const isEmpty = (display) => {
    return display == null || (typeof display === 'string' && !display.trim());
};

const transferString = (target) => {
    if (Array.isArray(target)) return target.map((x) => String(x)).splice(0);
    else if (isValid(target)) return String(target);
    return target;
};

const SelectInput = React.forwardRef((props, ref) => {
    const {
        'aria-describedby': ariaDescribedby,
        'aria-label': ariaLabel,
        autoFocus,
        autoWidth,
        children,
        className,
        defaultValue,
        disabled,
        displayEmpty,
        IconComponent = ArrowDropDownIcon,
        inputRef: inputRefProp,
        labelId,
        MenuProps = {},
        multiple,
        name,
        onBlur,
        onChange,
        onClose,
        onFocus,
        onOpen,
        open: openProp,
        readOnly,
        renderValue,
        tabIndex: tabIndexProp,
        // catching `type` from Input which makes no sense for SelectInput
        type,
        value: valueProp,
        // eslint-disable-next-line no-unused-vars
        SelectDisplayProps = {},
        // eslint-disable-next-line no-unused-vars
        variant = 'standard',
        // eslint-disable-next-line no-unused-vars
        defaultProperty,
        ...other
    } = props;

    const [value, setValueState] = useControlled({
        controlled: transferString(valueProp),
        default: transferString(defaultValue),
    });
    const inputRef = React.useRef(null);
    const displayRef = React.useRef(null);
    const [displayNode, setDisplayNode] = React.useState(null);
    const { current: isOpenControlled } = React.useRef(openProp != null);
    const [menuMinWidthState, setMenuMinWidthState] = React.useState();
    const [openState, setOpenState] = React.useState(false);
    const handleRef = useForkRef(ref, inputRefProp);

    React.useImperativeHandle(
        handleRef,
        () => {
            return {
                focus: () => {
                    displayRef.current.focus();
                },
                node: inputRef.current,
                name: inputRef.current?.name ?? '',
                id: inputRef.current?.id ?? '',
                value,
            };
        },
        [value]
    );

    React.useEffect(() => {
        if (autoFocus) {
            displayRef.current.focus();
        }
    }, [autoFocus]);

    React.useEffect(() => {
        const label = ownerDocument(displayRef.current).getElementById(labelId);
        if (label) {
            const handler = () => {
                if (getSelection().isCollapsed) {
                    displayRef.current.focus();
                }
            };
            label.addEventListener('click', handler);
            return () => {
                label.removeEventListener('click', handler);
            };
        }
        return undefined;
    }, [labelId]);

    const handleDisplayRef = React.useCallback((node) => {
        displayRef.current = node;
        node && setDisplayNode(node);
    }, []);

    const update = (open, event) => {
        if (open) {
            onOpen && onOpen(event);
        } else if (onClose) {
            onClose(event);
        }

        if (!isOpenControlled) {
            setMenuMinWidthState(autoWidth ? null : displayNode.clientWidth);
            setOpenState(open);
        }
    };

    const childrenArray = React.Children.toArray(children);
    const open = displayNode !== null && (isOpenControlled ? openProp : openState);

    const handleMouseDown = (event) => {
        // Ignore everything but left-click
        if (event.button !== 0) return;
        // Hijack the default focus behavior.
        event.preventDefault();
        displayRef.current.focus();
        update(true, event);
    };

    const handleClose = (event) => {
        update(false, event);
    };

    // Support autofill.
    const handleChange = (event) => {
        const index = childrenArray.map((child) => child.props.value).indexOf(event.target.value);

        if (index === -1) return;

        const child = childrenArray[index];
        setValueState(child.props.value);
        onChange && onChange(event, child, value);
    };

    const handleItemClick = (child) => (event) => {
        let newValue;
        // We use the tabindex attribute to signal the available options.
        if (!event.currentTarget.hasAttribute('tabindex')) return;
        if (multiple) {
            newValue = Array.isArray(value) ? value.slice() : [];
            const itemIndex = value.indexOf(child.props.value);
            if (itemIndex === -1) {
                newValue.push(child.props.value);
            } else {
                newValue.splice(itemIndex, 1);
            }
        } else {
            newValue = child.props.value;
        }

        child.props.onClick && child.props.onClick(event);

        if (value !== newValue) {
            setValueState(newValue);
            if (onChange) {
                // Redefine target to allow name and value to be read.
                // This allows seamless integration with the most popular form libraries.
                // https://github.com/mui-org/material-ui/issues/13485#issuecomment-676048492
                // Clone the event to not override `target` of the original event.
                const nativeEvent = event.nativeEvent || event;
                const clonedEvent = new nativeEvent.constructor(nativeEvent.type, nativeEvent);

                Object.defineProperty(clonedEvent, 'target', {
                    writable: true,
                    value: { value: newValue, name },
                });
                onChange(clonedEvent, child, newValue);
            }
        }

        if (!multiple) {
            update(false, event);
        }
    };

    const handleKeyDown = (event) => {
        if (!readOnly) {
            const validKeys = [
                ' ',
                'ArrowUp',
                'ArrowDown',
                // The native select doesn't respond to enter on MacOS, but it's recommended by
                // https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
                'Enter',
            ];

            if (validKeys.indexOf(event.key) !== -1) {
                event.preventDefault();
                update(true, event);
            }
        }
    };

    const handleBlur = (event) => {
        // if open event.stopImmediatePropagation  allen todo
        if (open && onBlur) {
            // Preact support, target is read only property on a native event.
            Object.defineProperty(event, 'target', { writable: true, value: { value, name } });
            onBlur(event);
        }
    };

    let display;
    let displaySingle;
    const displayMultiple = [];
    let computeDisplay = false;
    // let foundMatch = false;

    // No need to display any value if the field is empty.
    if (isFilled({ value }) || displayEmpty) {
        if (renderValue) {
            display = renderValue(value);
        } else {
            computeDisplay = true;
        }
    }
    // This is selectItem logic;
    const items = childrenArray.map((child) => {
        if (!React.isValidElement(child)) return null;
        let selected;
        if (multiple) {
            if (!Array.isArray(value)) {
                console.error('Allen: The `value` prop must be an array when using the `Select` component with `multiple`.');
            }
            selected = value.some((v) => areEqualValues(v, child.props.value));
            if (selected && computeDisplay) displayMultiple.push(child.props.children);
        } else {
            selected = areEqualValues(value, child.props.value);
            if (selected && computeDisplay) displaySingle = child.props.children;
        }

        return React.cloneElement(child, {
            'aria-selected': selected ? 'true' : undefined,
            onClick: handleItemClick(child),
            onKeyUp: (event) => {
                if (event.key === ' ') {
                    // otherwise our MenuItems dispatches a click event
                    // it's not behavior of the native <option> and causes
                    // the select to close immediately since we open on space keydown
                    event.preventDefault();
                }
                child.props.onKeyUp && child.props.onKeyUp(event);
            },
            role: 'option',
            selected,
            value: undefined, // The value is most likely not a valid HTML attribute.
            'data-value': child.props.value, // Instead, we provide it as a data attribute.
        });
    });

    if (computeDisplay) {
        display = multiple ? displayMultiple.join(', ') : displaySingle;
    }

    // Avoid performing a layout computation in the render method.
    let menuMinWidth = menuMinWidthState;

    if (!autoWidth && isOpenControlled && displayNode) {
        menuMinWidth = displayNode.clientWidth;
    }

    let tabIndex;
    if (typeof tabIndexProp !== 'undefined') {
        tabIndex = tabIndexProp;
    } else {
        tabIndex = disabled ? null : 0;
    }

    return (
        <React.Fragment>
            <SelectRoot
                ref={handleDisplayRef}
                tabIndex={tabIndex}
                onKeyDown={handleKeyDown}
                onMouseDown={disabled || readOnly ? null : handleMouseDown}
                onBlur={handleBlur}
                onFocus={onFocus}
                className={clsx(className)}
            >
                {/* So the vertical align positioning algorithm kicks in. */}
                {isEmpty(display) ? (
                    // notranslate needed while Google Translate will not fix zero-width space issue
                    // eslint-disable-next-line react/no-danger
                    <span className="notranslate" dangerouslySetInnerHTML={{ __html: '&#8203;' }} />
                ) : (
                    display
                )}
            </SelectRoot>
            <SelectNativeInput
                value={Array.isArray(value) ? value.join(',') : value || ''}
                name={name}
                ref={inputRef}
                aria-hidden
                onChange={handleChange}
                tabIndex={-1}
                disabled={disabled}
                autoFocus={autoFocus}
                {...other}
                id={SelectDisplayProps.id}
            />
            <SelectIcon as={IconComponent} open={open} disabled={disabled} />
            <Menu
                anchorEl={displayNode}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                {...MenuProps}
                MenuListProps={{
                    'aria-labelledby': labelId,
                    role: 'listbox',
                    disableListWrap: true,
                    ...MenuProps.MenuListProps,
                }}
                PaperProps={{
                    ...MenuProps.PaperProps,
                    style: {
                        minWidth: menuMinWidth,
                        ...(MenuProps.PaperProps != null ? MenuProps.PaperProps.style : null),
                    },
                }}
            >
                {items}
            </Menu>
        </React.Fragment>
    );
});

export default SelectInput;
