import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const MapPin = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M4.375,12.38V18.5l.86,1.29a.469.469,0,0,0,.78,0l.86-1.29V12.38a6.6,6.6,0,0,1-2.5,0ZM5.625,0A5.625,5.625,0,1,0,11.25,5.625,5.625,5.625,0,0,0,5.625,0Zm0,2.969A2.659,2.659,0,0,0,2.969,5.625a.469.469,0,0,1-.937,0A3.6,3.6,0,0,1,5.625,2.031a.469.469,0,0,1,0,.938Z"
                transform="translate(6 2)"
            ></path>
        </SvgIcon>
    );
});

export default MapPin;
