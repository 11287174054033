import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const File = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M8.75,5.313V0H.938A.935.935,0,0,0,0,.938V19.063A.935.935,0,0,0,.938,20H14.063A.935.935,0,0,0,15,19.063V6.25H9.688A.94.94,0,0,1,8.75,5.313ZM15,4.762V5H10V0h.238A.937.937,0,0,1,10.9.273L14.727,4.1A.935.935,0,0,1,15,4.762Z"
                transform="translate(5 2)"
            />
        </SvgIcon>
    );
});

export default File;
