import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const NavigateBefore = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="M7.24,11.34,12.55,6a.94.94,0,0,1,1.32,0h0l.89.89a.94.94,0,0,1,0,1.32h0L11,12l3.76,3.77a.93.93,0,0,1,0,1.32h0l-.88.89a.94.94,0,0,1-1.32,0h0L7.24,12.66a.93.93,0,0,1,0-1.32Z" />
        </SvgIcon>
    );
});

export default NavigateBefore;
