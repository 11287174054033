import React from 'react';
import useForkRef from '@Util/hook/useForkRef';

const Box = React.forwardRef((props, ref) => {
    const { children, className, style = {}, component: Component = 'div', ...other } = props;

    const boxRef = React.useRef(null);
    const combinedRef = useForkRef(ref, boxRef);

    return (
        <Component className={className} style={style} ref={combinedRef} {...other}>
            {children}
        </Component>
    );
});

export default Box;
