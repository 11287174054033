import React from 'react';
import clsx from 'clsx';
import useFormControl from '../formControl/useFormControl';
import useControlled from '@Util/hook/useControlled';
import ButtonBase from '../button/ButtonBase';

const staticButtonClass = `switch-base-button`;
const staticInputClass = `switch-base-input`;

const edgeMap = ['end', 'start'];

const SwitchBase = React.forwardRef((props, ref) => {
    const {
        defaultChecked,
        checked: checkedProp,
        disabled: disabledProp,
        id: propsId,
        checkedIcon,
        className: propsClassName,
        edge = false,
        icon,
        name,
        onChange,
        onBlur,
        onFocus,
        inputRef,
        inputProps,
        readOnly = false,
        required,
        tabIndex,
        type,
        value,
        size,
        handleCheckedFn,
        ...other
    } = props;

    const [checked, setCheckedState] = useControlled({
        controlled: checkedProp,
        default: Boolean(defaultChecked),
    });
    const formControl = useFormControl();
    let classButtonNames = staticButtonClass;
    let classInputNames = staticInputClass;

    const handleFocus = (event) => {
        onFocus && onFocus(event);

        if (formControl && formControl.onFocus) {
            formControl.onFocus(event);
        }
    };

    const handleBlur = (event) => {
        onBlur && onBlur(event);

        if (formControl && formControl.onBlur) {
            formControl.onBlur(event);
        }
    };

    const handleInputChange = (event) => {
        // Workaround for https://github.com/facebook/react/issues/9023
        if (event.nativeEvent.defaultPrevented) return;
        // readOnly no Change
        if (readOnly || disabledProp) return;
        const newChecked = event.target.checked;
        setCheckedState(newChecked);
        // TODO v6: remove the second argument.
        onChange && onChange(event, newChecked);
    };

    let disabled = disabledProp;
    if (formControl && typeof disabled === 'undefined') {
        disabled = formControl.disabled;
    }
    const hasLabelFor = type === 'checkbox' || type === 'radio';

    if (edgeMap.indexOf(edge) !== -1) classButtonNames = clsx(classButtonNames, `${staticButtonClass}-edge-${edge}`);
    if (size === 'small') classButtonNames = clsx(classButtonNames, `${staticButtonClass}-size-${size}`);
    if (handleCheckedFn) classButtonNames = handleCheckedFn(classButtonNames, checked); //props checked class

    const defaultProperty = {
        component: `span`,
        className: clsx(classButtonNames, propsClassName),
        disabled,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onClick: (e) => e.stopPropagation(),
        tabIndex: null,
        centerRipple: true,
        ref,
        ...other,
    };

    const inputProperty = {
        className: classInputNames,
        ref: (el) => {
            //apply current component change
            if (el) {
                el.setCheckedState = disabledProp ? () => {} : setCheckedState;
            }
            if (inputRef) {
                typeof inputRef === 'function' ? inputRef(el) : (inputRef.current = el);
            }
        },
        id: hasLabelFor && propsId,
        checked,
        disabled,
        name,
        tabIndex,
        readOnly,
        required,
        type,
        onChange: handleInputChange,
        ...(type === 'checkbox' && value === undefined ? {} : { value }),
        ...other,
        ...inputProps,
    };

    return (
        <ButtonBase {...defaultProperty}>
            <input {...inputProperty} />
            {checked ? checkedIcon : icon}
        </ButtonBase>
    );
});

export default SwitchBase;
