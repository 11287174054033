const bodyMeasurementsData = [
    {
        key: 'neckMC',
        name: '頸圍',
        unit: 'cm',
    },
    {
        key: 'chestMC',
        name: '胸圍',
        unit: 'cm',
    },
    {
        key: 'abdomenMC',
        name: '腹圍',
        unit: 'cm',
    },
    {
        key: 'hipMC',
        name: '臀圍',
        unit: 'cm',
    },
    {
        key: 'rightArmMC',
        name: '右臂',
        unit: 'cm',
    },
    {
        key: 'leftArmMC',
        name: '左臂',
        unit: 'cm',
    },
    {
        key: 'rightThighMC',
        name: '右大腿',
        unit: 'cm',
    },
    {
        key: 'leftThighMC',
        name: '左大腿',
        unit: 'cm',
    },
];

export default bodyMeasurementsData;
