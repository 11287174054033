import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const Trash = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <rect width="24" height="24" opacity="0" />
            <path
                d="M16.875,1.25H12.188L11.82.52A.937.937,0,0,0,10.98,0H6.516A.927.927,0,0,0,5.68.52l-.367.73H.625A.625.625,0,0,0,0,1.875v1.25a.625.625,0,0,0,.625.625h16.25a.625.625,0,0,0,.625-.625V1.875A.625.625,0,0,0,16.875,1.25ZM2.078,18.242A1.875,1.875,0,0,0,3.949,20h9.6a1.875,1.875,0,0,0,1.871-1.758L16.25,5h-15Z"
                transform="translate(3 2)"
            ></path>
        </SvgIcon>
    );
});

export default Trash;
