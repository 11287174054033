import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const UserHome = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M23.9,12c0,0.7-0.6,1.3-1.3,1.3h-1.3l0,6.6c0,1.5-1.2,2.7-2.6,2.7H5.4c-1.5,0-2.6-1.2-2.6-2.6v-6.6H1.4
	c-0.7,0-1.3-0.6-1.3-1.3c0-0.4,0.1-0.7,0.4-1l10.6-9.2c0.3-0.3,0.6-0.3,0.9-0.3s0.6,0.1,0.9,0.3L23.4,11
	C23.8,11.3,23.9,11.6,23.9,12L23.9,12z M12,8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S13.5,8,12,8z M10.7,14.6
	c-1.8,0-3.3,1.5-3.3,3.3c0,0.4,0.3,0.7,0.7,0.7H16c0.4,0,0.7-0.3,0.7-0.7c0-1.8-1.5-3.3-3.3-3.3H10.7z"
            />
        </SvgIcon>
    );
});

export default UserHome;
