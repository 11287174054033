import React, { createContext, useContext } from 'react';

const MeasurementContext = createContext(undefined);

export const MeasurementProvider = ({ children, value }) => {
    return <MeasurementContext.Provider value={value}>{children}</MeasurementContext.Provider>;
};

export const useMeasurementContext = () => {
    const context = useContext(MeasurementContext);
    if (context === undefined) {
        throw new Error('useGiftContext 必須在 GiftProvider 中使用');
    }
    return context;
};
